import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: '',
        // 控制iframe显隐
        iframeIsShow: false,
        // 充值游戏的信息
        topUpInfo: null,
        iframeGameUrl: null,
        isLogin: false,
        userInfo: {
            'figureurl': null
        }
    },
    mutations: {
        updateIframeIsShow (state) {
            state.iframeIsShow = !state.iframeIsShow;
        },
        updateToken (state, token) {
            state.token = token
        },
        updateTopUpInfo (state, param) {
            state.topUpInfo = param
        },
        updateIframeGameUrl (state, url) {
            state.iframeGameUrl = url
        },
        updateIsLogin(state, value) {
            state.isLogin = value
        },
        updateUserInfo(state, param) {
            state.userInfo = param;
        }
    },
    getters: {
        getUserInfo: state => {
            return state.userInfo
        }
    },
    actions: {
    },
    modules: {
    }
})
