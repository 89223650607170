import Vue from 'vue'
import { MessageBox, Message } from 'element-ui'

/**
 * 存储cookie
 * @param cname     cookie名字
 * @param cvalue    cookie值
 * @param exdays    过期时间，如果不设置 在关闭浏览器就会清楚
 */
function setCookie(cname, cvalue, exdays = 0) {
    if (exdays > 0) {
        var d = new Date().getTime() + exdays * 24 * 3600 * 1000 + 8 * 3600 * 1000;
        var expires = "expires=" + new Date(d).toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    } else {
        document.cookie = cname + "=" + cvalue + ";" + ";path=/";
    }
}

/**
 * 获取cookie
 * @param cname cookie的名字
 * @returns {string|any}
 */
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            var d = c.substring(name.length, c.length);
            return d
        }
    }
    return "";
}

/**
 * 删除cookie
 * @param name
 */
function deleteCookie(name) {
    var date = new Date();
    date.setTime(date.getTime() - 1);
    var delValue = getCookie(name);
    if (delValue) {
        document.cookie = name + "=" + delValue + ";expires=" + date.toGMTString();
    }
}

function isHttps() {
    return 'https:' == document.location.protocol ? true : false
}


/**
 * 验证实名信息是否可以玩游戏
 */
function testIdcard(id) {
	let idcard = id
	// 年龄满18验证
	// 出生年月日 362423 2000 06 20
	var userBir = parseInt(idcard.substr(6, 8))
	var nowTime = new Date()
	var nowYear = nowTime.getFullYear()
	var nowMonth = nowTime.getMonth()+1
	var nowDay = nowTime.getDate()
	console.log(nowMonth)
	if(nowMonth < 10){
		nowMonth = "0"+nowMonth
	}
	if(nowDay < 10){
		nowDay = "0"+nowDay
	}
	var now = parseInt(nowYear+""+nowMonth+""+nowDay)
	
	// 获取星期几 ， 0 5 6可以玩 8点到9点可以玩
	var week = nowTime.getDay()
	var hours = nowTime.getHours()
	
	if(userBir + 180000 > now){	// 身份证小于18岁的未成年， 分条件
		if((week <= 0 || week >= 5) && (hours >= 20 && hours <= 21)){	// 只能在 星期 五 六 天， 晚上 20-21 点可以玩
			return true
		}else {
			return false
		}
	}else {	// 大于18岁直接玩
		return true;
	}
}


export default {
    setCookie,
    getCookie,
    deleteCookie,
    isHttps,
		testIdcard
}