import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        // 加载首页
        path: '/',
        name: 'Home',
        component: () => import('../views/Index.vue')
    },
    {
        // 游戏详情页面
        path: '/detail',
        name: 'Detail',
        component: () => import('../views/Detail.vue')
    },
    {
        // QQ登录的回调地址
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        // 微信登录的回调地址
        path: '/loginWx',
        name: 'LoginWx',
        component: () => import('../views/WxLogin')
    },
    {
        // 手机页面游戏详情页
        path: '/phone',
        name: 'PhoneDetail',
        component: () => import('../views/DetailPhone')
    },
    {
        // 搜索页面
        path: '/search',
        name: 'Search',
        component: () => import('../views/Search')
    },
    {
        // 开始游戏页面
        path: '/play',
        name: 'Play',
        component: () => import('../views/Play')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
