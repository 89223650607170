<template>
    <div class="container">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Bodys"
}
</script>

<style scoped lang="less">
    .container {
        width: 100%;
        z-index: 100;
        background: #F1F1F1;
    }
</style>