import axios from "axios"
var isHttps = 'https:' == document.location.protocol ? "https" : "http";
export function h5Request(config) {
	return new Promise((resolev, reject) => {
		// 创建axios实例
		const instance = axios.create({
			// baseURL: "http://47.115.41.179:3400"
			baseURL: isHttps + "://h5.yunjiwan.com"
		})

		instance(config).then((result) => {
			resolev(result)
			return false
		}).catch((error) => {
			reject(error)
		})
	})
}

export function boxRequest(config) {
	return new Promise((resolev, reject) => {
		// 创建axios实例
		const instance = axios.create({
			baseURL: isHttps + "://box.yunjiwan.com"
			// baseURL: "http://localhost"
		})

		instance(config).then((result) => {
			resolev(result)
			return false
		}).catch((error) => {
			reject(error)
		})
	})
}

// box2

export function box2Request(config) {
	return new Promise((resolev, reject) => {
		// 创建axios实例
		const instance = axios.create({
			baseURL: isHttps + "://m.app.yunjiwan.com"
			// baseURL: "http://localhost"
		})

		instance(config).then((result) => {
			resolev(result)
			return false
		}).catch((error) => {
			reject(error)
		})
	})
}
