import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/idangerous.swiper2.7.6.css'
import './assets/js/flexible.min'
import './plugins/element.js'
import './assets/css/common.css'
import './assets/css/Public.less'
import './assets/js/qc_jssdk.js'

import Public from './assets/js/Public'
Vue.prototype.Public = Public
import { h5Request, boxRequest, box2Request } from './network/request.js'
Vue.prototype.h5Request = h5Request
Vue.prototype.boxRequest = boxRequest
Vue.prototype.box2Request = box2Request

Vue.config.productionTip = false


import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error=> error)
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
