<template>
    <div id="app">
        <Bodys></Bodys>
    </div>
</template>

<script>
import Bodys from "@/components/layout/Bodys";
export default {
    name: "app",
    data () {
        return {
        }
    },
    created() {
        // 获取token
        var token = this.Public.getCookie('token')
        if(!token) {
            try {
                if(this.isPc())
                {
                    window.cplusRunH5Game(this.getUserToken);
                } else {
                    // IOS端调用这个函数调用token
                    window.webkit.messageHandlers.iosFunc_getUserToken.postMessage('hello')
                }

                var that = this;
                window.getUserTokenCallBack = function (token) {
                    if(token) {
                        that.$store.commit('updateToken', token);
                        that.Public.setCookie('token', token)
                    }
                }

                window.loginSuccessCallBack = function (token) {
                    if(token) {
                        that.$store.commit('updateToken', token);
                        that.Public.setCookie('token', token)
                    }
                }
            } catch (e) {

            }
        } else {
            this.boxRequest({
                url: "/Home/User/checkUserByToken",
                method: "post",
                data: { token: token }
            }).then(result => {
                if(result.data.code == 200) {
                    this.$store.commit('updateIsLogin', true)
                    this.$store.commit('updateToken', token);
                    this.$store.commit('updateUserInfo', result.data.result)
										localStorage.setItem('userId',result.data.result.user_id)
                } else {
                    this.$message("服务器出现异常！获取用户信息失败");
                }
            }).catch(error => {
                this.$message("服务器出现异常！获取用户信息失败");
            })
        }
    },
    mounted() {
        window.addEventListener('message', (e) => {
            var data = e.data;
            switch (data.code) {
                case 100:
                    this.$store.commit('updateIframeIsShow')
                    // 保存信息到vuex
                    this.$store.commit('updateTopUpInfo', e.data.param)
                    // this.$router.push('/pay')
                    break;
                case 101:
                    break;
            }
        })
    },
    methods: {
        getUserToken(token) {
            if(token) {
                this.boxRequest({
                    url: "/Home/User/checkUserByToken",
                    method: "post",
                    data: { token: token }
                }).then(result => {
                    if(result.data.code == 200) {
                        this.Public.setCookie('token', token)
                        this.$store.commit('updateToken', token);
                        this.$store.commit('updateUserInfo', result.data.result)
                        this.$store.commit('updateIsLogin', true)
                    }
                }).catch(error => {
                    this.$message({
                        type: 'error',
                        message: "服务器出现异常！获取用户信息失败"
                    });
                })
            } else {
                // this.$message({
                //     type: 'error',
                //     message: "获取客户端token失败"
                // });
            }
        },
        isPc() {
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
                return false;
            }else{
                return true;
            }
        }
    },
    components: {
        Bodys
    }
}
</script>

<style lang="less" scoped>
    #app {
        position: relative;
        width: 100%;
        height: 100%;
    }
</style>
